//comentario de prueba git
import { Col } from "react-bootstrap";
export const CustomColFooter = ({ content = <></> }) => {
  return (
    <>
      <Col md={4} sm={6} xs={12}>
        {content}
      </Col>
    </>
  );
};
