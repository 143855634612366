import {useSelector} from "react-redux";
import { CustomColFooter } from "./CustomColFooter";
import { CustomSeparadorFooter } from "./CustomSeparadorFooter";
import { styleFooter } from "../helpers/styleFooter";
const oficinas = {
  title: "Oficinas",
  text: `Plaza Las Vías, Local 8, Calle 10 por 9 Col. Ampliación Plan de Ayala, Mérida`,
  //correo: "mailto:atencioncliente@nuskah.com",
};
const { styleTitle, styleText } = styleFooter;
export const CustomColOficinas = () => {

    const state = useSelector((state) => state.UI);
// console.log(state);

  return (
    <>
      <CustomColFooter
        content={
          <>
            <div className="text-white">
              <h5 className="text-nuskah text-center" style={styleTitle}>
                {oficinas.title}
              </h5>
              <CustomSeparadorFooter />
              <p className="text-center" style={styleText}>
                {oficinas.text}
              </p>
              <div className="d-flex justify-content-center ">
                <a href={`mailto:${state.email}`} className="a text-center">
                  Atención al cliente
                </a>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
