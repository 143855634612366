import {  Container, Row } from "react-bootstrap";
import { CardDesarrollos } from "../components/CardDesarrollos";
import { CardMapa } from "../components/CardMapa";
import { useSelector } from "react-redux";
const style = {
  marginLeft: ".1em",
  marginRight: ".1em",
};
export const HomePage = () => {
  const {infoCurrent} = useSelector(
    (state) => state.UI
  );
  return (
    <>
      <Container fluid>
        <Row style={style}>
          <>
          {infoCurrent?.condominio && 
          (
          <h5 style={{textAlign: 'center'}} className="fw-normal mb-5"> {infoCurrent?.desarrollo+' > '}{infoCurrent?.condominio}</h5>)}
          </>
          <CardDesarrollos />
          <CardMapa />
        </Row>
      </Container>
    </>
  );
};
