import {useSelector} from "react-redux";
import { Container } from "react-bootstrap";
import { CustomSeparador } from "./CustomSeparador";
const style = {
  marginBottom: ".7em",
};
const style_2 = {
  marginTop: "1em",
  marginBottom: "1.5em",
};
export const CustomTitleHeader = () => {

    const {tituloHeader, textHeader} = useSelector((state) => state.UI);

  return (
    <>
      <Container fluid style={style}>
        <h1 className="text-center">{tituloHeader}</h1>
      </Container>
      <CustomSeparador />
      <Container fluid style={style_2}>
        <h5 className="text-center">{textHeader}</h5>
      </Container>
    </>
  );
};
