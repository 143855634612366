const style = {
  height: ".1em",
  background: "#fff",
  width: "70%",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "1.5em",
  marginBottom: "1.2em",
};
export const CustomSeparadorFooter = () => {
  return (
    <>
      <div style={style}></div>
    </>
  );
};
