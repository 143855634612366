export const actionSetInitialData = (state, action) => {
  const {
    tituloHeader,
    textHeader,
    email,
    telMID,
    telQRO,
    telIAH,
    terminos,
    desarrollos,
    condominios,
  } = action.payload;
  state.tituloHeader = tituloHeader;
  state.textHeader = textHeader;
  state.email = email;
  state.telMID = telMID;
  state.telQRO = telQRO;
  state.telIAH = telIAH;
  state.terminos = terminos;
  state.desarrollos = desarrollos;
  state.condominios = condominios;
  state.terminos = terminos;
};
