import { createAsyncThunk } from "@reduxjs/toolkit";
import { homeGetInitialData } from "../../../../modules/home/API/homeGetInitialData";

export const thunkGetInitialData = createAsyncThunk(
  "UI/thunkGetInitialData",
  async () => {
    const response = await homeGetInitialData();
      return response.data;
  }
);
