import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { CustomImgHeader } from "../components/CustomImgHeader";
import { CustomNavbar } from "../components/CustomNavbar";
import { PropTypes } from "prop-types";
import { CustomTitleHeader } from "../components/CustomTitleHeader";
import { CustomFooter } from "../components/CustomFooter";

import { thunkGetInitialData } from "../store/slices/UI/thunks/thunkGetInitialData";

export const MainLayout = ({ content = <></> }) => {
  const { terminos } = useSelector((state) => state.UI);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(thunkGetInitialData());
  }, [dispatch]);

  return (
    <>
      <Container fluid>
        <Row>
          <CustomNavbar />
          <CustomImgHeader />
          <CustomTitleHeader />
          {content}
          <h5 className="text-center mt-5">Términos y condiciones</h5>
          <p className="text-center mt-3">{terminos}</p>
        </Row>
      </Container>
      <CustomFooter />
    </>
  );
};

MainLayout.propTypes = {
  content: PropTypes.element.isRequired,
};
