import { Container, Row, Col } from "react-bootstrap";
import logo from "../public/logo.png";
import { CustomColOficinas } from "./CustomColOficinas";
import { CustomColTelefonos } from "./CustomColTelefonos";
const style = {
  height: "600px",
  background: "#212529",
  marginTop: "2em",
};

const styleFooter = {
  marginLeft: "2em",
  marginRight: "2em",
  marginTop: "4em",
};
const styleimg = {
  display: "flex",
  justifyContent: "center",
  marginTop: "4em",
};
export const CustomFooter = () => {
  return (
    <>
      <Container fluid style={style}>
        <Row>
          <Col md={12} sm={12} style={styleimg}>
            <img alt="logo" width="150" src={logo} />
          </Col>
        </Row>
        <Row style={styleFooter} className="">
          <CustomColOficinas />
          <CustomColTelefonos />
        </Row>
      </Container>
    </>
  );
};
