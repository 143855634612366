export const actionsSetCL = (state, action) => {
  state.currentRender = action.payload.render;
  state.currentSvg = action.payload.svg;
  state.currentLotes = action.payload.lotes;
  const obj = {
    condominio: action.payload.nombre_condominio,
    desarrollo: action.payload.nombre_desarrollo,
  };
  state.infoCurrent = obj;
};
