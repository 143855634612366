import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MainLayout } from "../layouts/MainLayout";
import { HomePage } from "../modules/home/pages/HomePage";
export const AppRouter = () => {
  return (
    <>
      <MainLayout
        content={
          <Router>
            <Routes>
              <Route element={<HomePage />} path="/" />
            </Routes>
          </Router>
        }
      />
    </>
  );
};
