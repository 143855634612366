import { useDispatch, useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { sinMP } from "../../../helpers/styleG";
import { homeGetSvg } from "../API/homeGetSvg";
import { setLote } from "../../../store/slices/UI";
import { useState } from "react";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte

const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  },
  types: [
    {
      type: 'warning',
      background: 'orange',
      icon: false
    }
  ]
});

export const CardMapa = () => {
  const dispatch = useDispatch();

  const { currentLote, currentRender, currentSvg, currentLotes } = useSelector(
    (state) => state.UI
  );



  const [stateModal, setStateModal] = useState(false);


  const pathRender = `${process.env.REACT_APP_BACKEND_URL}/get-render/${currentRender}`;

  const getSvg = async () => {
    const svg = document.querySelector(`#svg`);
    const { data } = await homeGetSvg(currentSvg);
    svg.innerHTML = data;
    svg.style.position = "absolute";
  };


  const load = async () => {
    if (currentSvg != null && currentRender != null) {
      await getSvg();
      const svgCapa = document.querySelector(`#Capa_1`);
      svgCapa.classList.add("imgSvg");
      svgCapa.childNodes.forEach(rawElement => {
        if (rawElement.tagName && rawElement.id) {
          const polygonElement = currentLotes.find(lote => lote.nombre_unidad === rawElement.id)
          if (polygonElement) rawElement.classList.add(polygonElement.status)
        }
      });
    }
  };

  load();


  const handleClick = ({ target }) => {
    if (target.id) {
      const polygonElement = currentLotes.find(lote => lote.nombre_unidad === target.id && lote.status === 'disponible');
      const polygonElementBloqueado = currentLotes.find(lote => lote.nombre_unidad === target.id && lote.status === 'bloqueado');
      const polygonElementVendido = currentLotes.find(lote => lote.nombre_unidad === target.id && lote.status === 'vendido');
      const polygonElementApartado = currentLotes.find(lote => lote.nombre_unidad === target.id && lote.status === 'apartado');

      if(polygonElementBloqueado){
        notyf.open({
          type: 'warning',
          message: `Elemento bloqueado`
        });
        return
      }
      if(polygonElementVendido){
        notyf.error('Elemento vendido');
        return
      }
      if(polygonElementApartado){
        notyf.error('Elemento apartado');
        return
      }
      if (polygonElement) {
        dispatch(setLote({ lote: polygonElement }));
        setStateModal(true);
        return
      }
    }
  };

  return (
    <>
      <Col md={9} sm={12} style={sinMP}>
        <div className="capa" onClick={handleClick}>
          {currentSvg != null && currentRender != null && (
            <>
              <img src={pathRender} alt="render" className="imgRender" />
            </>
          )}
          <div id="svg" className="wrapperSvg"></div>
        </div>
      </Col>



      <Modal
        show={stateModal}
        onHide={() => setStateModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {
          currentLote  && <>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Unidad: {currentLote.nombre_unidad}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>Referencia: {currentLote.referencia ?? ''}</h6>
              <hr></hr>
              <h6>Superficie en (m2): {currentLote.m2 ?? ''}</h6>
              <hr></hr>
              <h6>Apartar con: $5,000.00</h6>
              <hr></hr>
              <div className="d-flex ma-0 mt-3 justify-content-end">
                <a className="btn btn-primary" href={process.env.REACT_APP_BACKEND_URL_PAGAR + `/${currentLote.referencia}`} >Pagar</a>
              </div>
            </Modal.Body>
          </>
        }
      </Modal>




    </>
  );
};
