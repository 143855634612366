import { useSelector } from "react-redux";
import { CustomColFooter } from "./CustomColFooter";
import { CustomSeparadorFooter } from "./CustomSeparadorFooter";
import { styleFooter } from "../helpers/styleFooter";
const telefonosIt = {
  title: "Más información",
};
const { styleTitle, styleText } = styleFooter;
export const CustomColTelefonos = () => {

  const { telMID, telQRO, telIAH } = useSelector((state) => state.UI);

  return (
    <>
      <CustomColFooter
        content={
          <>
            <div className="text-white ">
              <h5 className="text-nuskah text-center" style={styleTitle}>
                {telefonosIt.title}
              </h5>
              <CustomSeparadorFooter />

              <p className="text-center m-0" style={styleText}>
                Merida
              </p>
              <p className="text-center m-0" style={styleText}>
                Tel: {telMID}
              </p>

              <p className="text-center m-0 mt-1" style={styleText}>
                Querétaro
              </p>
              <p className="text-center m-0" style={styleText}>
                Tel: {telQRO}
              </p>

              <p className="text-center m-0 mt-1" style={styleText}>
                Houston
              </p>
              <p className="text-center m-0" style={styleText}>
                Tel: {telIAH}
              </p>
            </div>
          </>
        }
      />
    </>
  );
};
