import { BACKEND } from "./BACKEND";

export const homeGetSvg = async(currentSvg) => {
    try {
        const { data } = await BACKEND.get(`/get-svg/${currentSvg}`);
        return { data };
    } catch (error) {
        console.log(error.response, error.response.status);
        return { data: "<p>No SVG</p>" };
    }
};

// este metodo obtiene el hash y datos que iran en el formulario para realizar el pago en Banbajio
export const getDataForm = async(referencia) => {

    try {
        const { data } = await BACKEND.post(`/buildData`, {
            referencia
        });
        return { data, status: true };
    } catch (error) {
        console.log(error)
        return { status: false };
    }

};