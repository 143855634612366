import axios from "axios";

const base_url = process.env.REACT_APP_BACKEND_URL;

const headerAcept = `application/json`;
//
const BACKEND = axios.create({
  baseURL: base_url,
});
//
BACKEND.interceptors.request.use(async (req) => {
  req.headers.Accept = headerAcept;
  return req;
});
export { BACKEND };
