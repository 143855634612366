import { createSlice } from "@reduxjs/toolkit";
import { actionSetInitialData } from "./actions/actionSetInitialData";
import { actionSetLote } from "./actions/actionSetLote";
import { actionsSetCL } from "./actions/actionsSetCL";
import { thunkGetInitialData } from "./thunks/thunkGetInitialData";

const initialState = {
  tituloHeader: "",
  textHeader: "",
  telMID: "",
  telQRO: "",
  telIAH: "",
  terminos: "",
  desarrollos: [],
  currentRender: null,
  currentSvg: null,
  currentLotes: [],
  currentLote: null,
  infoCurrent:null,
  carrito: [], //almacenara los lotes en deseados []:<lote>
};

export const UISlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    setCL: actionsSetCL,
    setLote: actionSetLote,
  },
  extraReducers: (builder) => {
    builder.addCase(thunkGetInitialData.fulfilled, actionSetInitialData);
  },
});

const UIReducer = UISlice.reducer;
const { setCL, setLote } = UISlice.actions;
export { UIReducer, setCL, setLote };
