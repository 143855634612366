import { Col, Card, Accordion, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sinMP } from "../../../helpers/styleG";
import { setCL } from "../../../store/slices/UI";

const cp = {
  cursor: "pointer",
};

export const CardDesarrollos = () => {
  const dispatch = useDispatch();
  const { desarrollos } = useSelector((state) => state.UI);

  const handleCondominio = (
    { render, svg, lotes, nombre: nombre_condominio },
    { nombre: nombre_desarrollo }
  ) => {
    dispatch(
      setCL({ render, svg, lotes, nombre_condominio, nombre_desarrollo })
    );
  };

  return (
    <>
      <Col md={3} sm={12} style={sinMP}>
        <Card style={sinMP}>
          <Card.Header>Desarrollos</Card.Header>
          <Card.Body style={sinMP}>
            <Accordion>
              {desarrollos.map((desarrollo, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>{desarrollo.nombre}</Accordion.Header>
                  <Accordion.Body className="p-0">
                    <ListGroup as="ul">
                      {desarrollo.condominios.map((condominio, index_con) => (
                        <ListGroup.Item
                          as="li"
                          key={`condominio_${index_con}`}
                          style={cp}
                          onClick={() =>
                            handleCondominio(condominio, desarrollo)
                          }
                        >
                          {condominio.nombre}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};
