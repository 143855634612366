import header from "../public/header.png";
const styleHeader = {
  backgroundImage: `url(${header})`,
  width: "100%",
  height: "250px",
  marginBottom: "30px",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  textAlign: "center",
  color: "#fff",
  position: "relative",
  backgroundSize: "cover !important",
  backgroundPosition: "center",
};
export const CustomImgHeader = () => {
  return (
    <>
      <div style={styleHeader}></div>
    </>
  );
};
