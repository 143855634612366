const style = {
  height: ".1em",
  background: "#E7C285",
  width: "30%",
  marginLeft: "auto",
  marginRight: "auto",
};
export const CustomSeparador = () => {
  return (
    <>
      <div style={style}></div>
    </>
  );
};
