import { BACKEND } from "./BACKEND";

const initialState = {
    tituloHeader: "",
    textHeader: "",
    email: "",
    telMID: "",
    telQRO: "",
    telIAH: "",
    terminos: "",
    desarrollos: [],
};

const buildDesarrollo = (desarrollo) => {
    desarrollo.condominios = [];
    return desarrollo;
};

export const homeGetInitialData = async() => {
    try {
        const {
            data: {
                data: { desarrollos, condominios, titulos },
            },
        } = await BACKEND.get("/get-data");

        initialState.tituloHeader = '';
        initialState.textHeader = '';
        initialState.email = '';
        initialState.telMID = '';
        initialState.telQRO = '';
        initialState.telIAH = '';
        initialState.terminos = '';

        console.log(titulos);

        const nd = desarrollos.map((desarrollo) => {
            return buildDesarrollo(desarrollo);
        });

        condominios.forEach((condominio) => {
            nd.forEach((desarrollo) => {
                if (condominio.desarrollo_id === desarrollo.id) {
                    desarrollo.condominios.push(condominio);
                }
            });
        });

        initialState.desarrollos = nd;
        // titulos
        initialState.email = titulos.find((titulo) => titulo.id === 3).text;
        initialState.telMID = titulos.find((titulo) => titulo.id === 4).text;
        initialState.telQRO = titulos.find((titulo) => titulo.id === 5).text;
        initialState.telIAH = titulos.find((titulo) => titulo.id === 6).text;
        initialState.tituloHeader = titulos.find((titulo) => titulo.id === 1).text;
        initialState.textHeader = titulos.find((titulo) => titulo.id === 2).text;
        initialState.terminos = titulos.find((titulo) => titulo.id === 7).text;

        return {
            data: initialState,
        };
    } catch (error) {
        console.log(error.response, error.response.status);
        return {
            data: initialState,
        };
    }
};