import { Navbar, Container, Nav } from "react-bootstrap";
import logo from "../public/logo.png";
export const CustomNavbar = () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        sticky="top"
      >
        <Container fluid>
          <Navbar.Brand >
            <img
              alt=""
              width="50"
              height="30"
              className="d-inline-block align-top"
              src={logo}
            />
            {"  "}
            Nuskah
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
